<template>
        <tr>
            <td>{{ id }}</td>
            <td>{{ firstname }}</td>
            <td>{{ lastname }}</td>
            <td>{{ company }}</td>
            <td>{{ street }}</td>
            <td>{{ zip }}</td>
            <td>{{ city }}</td>
            <td>{{ phone }}</td>
            <td>{{ mobile }}</td>
            <td>{{ email }}</td>
            <td>{{ birthday }}</td>
            <td>{{ nl_optin }}</td>
            <td>{{ nl_optin_at }}</td>
            <td>{{ nl_optin_ip }}</td>
            <td>{{ nl_optin_code }}</td>
            <td>{{ nl_optin_link }}</td>
            <td>{{ nl_optin_link_send_at }}</td>
            <td>{{ nl_optin_entry }}</td>
            <td>{{ nl_optin_confirm_at }}</td>
            <td>{{ nl_optin_confirm_ip }}</td>
            <td>{{ nl_optout_at }}</td>
            <td>{{ nl_code }}</td>
            <td>{{ nl_id }}</td>
        </tr>
</template>


<script>
    export default {
        name: 'TabelleZeile',
        props: {
            id: Number,
            salutation: String,
            firstname: String,
            lastname: String,
            company: String,
            street: String,
            zip: String,
            city: String,
            phone: String,
            mobile: String,
            email: String,
            birthday: String,
            nl_optin: String,
            nl_optin_at: String,
            nl_optin_ip: String,
            nl_optin_code: String,
            nl_optin_link: String,
            nl_optin_link_send_at: String,
            nl_optin_entry: String,
            nl_optin_confirm_at: String,
            nl_optin_confirm_ip: String,
            nl_optout_at: String,
            nl_code: String,
            nl_id: String
        },
        data() {
            return {
            }
        }
    }

</script>

