<template>
  <div class="table">
    <table>
      <tbody>
        <TabelleZeile 
            :key="dat.id_md_data" 
            :id="dat.id_mdid" 
            :salutation="dat.salutation" 
            :firstname="dat.firstname" 
            :lastname="dat.lastname" 
            :company="dat.company" 
            :street="dat.street" 
            :zip="dat.zip" 
            :city="dat.city" 
            :phone="dat.phone" 
            :mobile="dat.mobile" 
            :email="dat.email" 
            :birthday="dat.birthday" 
            :nl_optin="dat.nl_optin" 
            :nl_optin_at="dat.nl_optin_at" 
            :nl_optin_ip="dat.nl_optin_ip" 
            :nl_optin_code="dat.nl_optin_code" 
            :nl_optin_link="dat.nl_optin_link" 
            :nl_optin_link_send_at="dat.nl_optin_link_send_at" 
            :nl_optin_entry="dat.nl_optin_entry" 
            :nl_optin_confirm_at="dat.nl_optin_confirm_at" 
            :nl_optin_confirm_ip="dat.nl_optin_confirm_ip" 
            :nl_optout_at="dat.nl_optout_at" 
            :nl_code="dat.nl_code" 
            :nl_id="dat.nl_id" 
            v-for="dat in daten" />
      </tbody>
    </table>
  </div>
</template>

<script>
    import TabelleZeile from '@/components/TabelleZeile.vue';

    export default {
        name: 'TabelleView',
        components: {
          TabelleZeile
        },

        data() {
            return {
                daten: []
            }
        },

        methods: {
            async getData(){
                //const url = 'https://api.edamam.com/search?q=sushi&app_id=d4519661&app_key=0c66a56c6caef858b20842f9e3add333';
                //const url = 'https://www.montagsgesellschaft.de/vuetest.php';
                const url = './vuetest.html';
                let resp = await fetch(url);
                this.daten = (await resp.json()).data;
console.log('A');
console.log(this.daten);
            }

        },

        mounted() {
            this.getData();
        }

    }
</script>

<style>

</style>
